import React, { useState } from 'react'
import PrimaryLayout from '../../Components/common/layout/PrimaryLayout'
import HomeLandingSections from '../../Components/common/HomeLandingSections/HomeLandingSections'
import OfferingsInfoComponent from '../../Components/main/OfferingsInfoComponent/OfferingsInfoComponent'
import { OfferingsInfoComponentCardImgs } from '../../Static/constants/imgConstants/imgConstants'
import PortfoliosSec from '../../Components/main/PortfoliosSec/PortfoliosSec'
import { Helmet } from 'react-helmet'

function FintechSolutions({ setSelectedPortfolio, setSelectedCard, setSelectedProduct }) {
    const [data] = useState([
        {
            id: "atm-ims",
            title: "ATM IMS",
            content: [
                "Managing the complexity of multi-networks entailed intricate integration for handling diverse bank ATM networks, while the need for continuous monitoring across an expansive network necessitated the use of advanced technology. Coordinating multiple parties with varying roles presented a logistical challenge in incident management coordination.",
                "The implementation of a unified platform streamlined multi-network ATM systems, resulting in a reduction of complexity and an enhancement of overall efficiency. State-of-the-art monitoring tools were introduced to ensure continuous vigilance over critical indicators, and a robust ticket life cycle management system was implemented to facilitate efficient incident tracking and resolution.",
                "The focus on achieving optimal utilization of the field force led to speedy ATM issue resolution, supported by an efficient escalation process and comprehensive ticket resolution history. Additionally, the implementation of a seamless ticket assignment system among team members improved operational visibility for middle and higher management."

            ],
            bgImg: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardImg1,
            icon: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardIconDark1,
            isReverse: false,
            metaTitle: '',
            metaDescription: '',
            metaKeyword: '',
            metaURL: '',
        },
        {
            id: "digital-payment-platform",
            title: "Digital Payment Platform",
            content: [
                "Addressing complex third-party API integration and server-to-server API calls, our efforts encompass providing both prepaid virtual and physical cards, necessitating a focus on security and seamless integration with payment systems. Integrating eWallets into the platform posed a critical challenge, requiring us to maintain security and user trust. It's important to note that PPI is a regulatory business subject to the intricate norms set forth by the RBI.",
                "The implementation of scalable microservices has provided essential support for our business, ensuring a seamless user experience on both iOS and Android platforms. These services were further enhanced by integrating various payment options, creating a one-stop solution for multiple utility bill payments. Our secure backend system caters to Cobrands and the administrative team, while we also prioritize the incorporation of robust security protocols. Additionally, the successful integration of eWallets has expanded our offerings to provide flexible payment options for our customers.",
                "We have successfully established our client as a leading Fintech service company, providing users with a seamless experience through a single platform for various utility bill payments, thereby reducing complexity and saving valuable time. Additionally, by integrating eWallets, users now have access to a broader range of payment methods, offering greater convenience and choice in their financial transactions."

            ],
            bgImg: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardImg2,
            icon: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardIconDark2,
            isReverse: true,
            metaTitle: '',
            metaDescription: '',
            metaKeyword: '',
            metaURL: '',
        },
        {
            id: "smart-saving-platform",
            title: "Smart Saving Platform",
            content: [
                "Many young individuals often lack financial literacy, which can hinder their ability to make informed investment decisions. Young investors may also face challenges in creating holistic family-centric financial portfolios. The wide array of investment options, ranging from digital gold to insurance, can be overwhelming for young savers.",
                "Our platform plays a pivotal role in enhancing financial literacy, empowering users to make informed investment decisions. With a user-friendly interface, our app simplifies the process of investing in digital assets and prepaid cards. Moreover, users have the convenience of creating family profiles within the app, allowing them to initiate savings for loved ones and develop holistic financial portfolios effortlessly.",
                "The app serves as an empowering tool for the younger generation, equipping them with the knowledge and tools necessary to take control of their financial futures. Users have the opportunity to explore a diverse range of investment options, spanning from digital gold to insurance, enabling them to diversify their financial portfolios. Additionally, the app fosters a sense of collaboration among family members, encouraging them to collectively plan their financial future and ensure the overall financial well-being of the family as a whole."

            ],
            bgImg: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardImg3,
            icon: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardIconDark3,
            isReverse: false,
            metaTitle: '',
            metaDescription: '',
            metaKeyword: '',
            metaURL: '',
        },
        {
            id: "door-pay",
            title: "Door Pay",
            content: [
                "The integration of micro ATMs and mobile banking apps into the existing banking infrastructure while ensuring compliance with the established authorization processes set by the Central bank marked a convergence of technology and security. On one hand, establishing partnerships with local businesses to serve as banking points necessitated thorough due diligence and adherence to legal formalities. On the other hand, it involved the task of encouraging customers to embrace these new banking methods.",
                "A robust integration of micro ATMs and mobile apps into the banking system was executed, along with the implementation of biometric verification and two-factor authentication for enhanced security. Collaborating with local businesses as franchises and offering incentives to them played a key role in expanding the network. Additionally, awareness campaigns and user training programs, with a specific focus on older individuals, were conducted. Moreover, provisions were made to add more services in a nutshell, such as bill/mobile payments and digital gold purchases",
                "The introduction of these services has significantly improved accessibility to financial services for residents in remote areas, providing greater convenience for elderly and mobility-impaired individuals who can now conduct banking transactions from the comfort of their homes. Premier customers have benefitted from reduced queues, avoiding the inconvenience of long waits at banks or ATMs, and the implementation of advanced security measures has effectively lowered the risk of fraudulent transactions, ensuring secure financial transactions for all."

            ],
            bgImg: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardImg4,
            icon: OfferingsInfoComponentCardImgs.OfferingsInfoComponentCardIconDark4,
            isReverse: true,
            metaTitle: '',
            metaDescription: '',
            metaKeyword: '',
            metaURL: '',
        }
    ])
    return (
        <div className='fintech-solutions'>
            <PrimaryLayout
                showNavbar={true}
                showContactUs={false}
                showFooterMain={true}
                showFooterMini={true}
                isContactUsAltBg={false}
                setSelectedPortfolio={setSelectedPortfolio}
                setSelectedCard={setSelectedCard}
                setSelectedProduct={setSelectedProduct}
            >
                <HomeLandingSections
                    title1={`Empowering the`}
                    title2={`\nFuture`}
                    subtitle={`Effortless Digital Payments, Seamless\nCurrency Exchange, Across Borders`}
                    route={"fintech"}
                />
                <OfferingsInfoComponent />
                {
                    data.map((item) => {
                        return (
                            <>
                                <Helmet>
                                    <title></title>
                                    <meta name="description" content={data.metaTitle} />
                                    <meta name="keywords" content={data.metaKeyword} />
                                    <meta name="author" content={data} />
                                    <meta property="og:title" content={data.metaTitle} />
                                    <meta property="og:description" content={data.metaDescription} />
                                    <meta property="og:image" content={data} />
                                    <meta property="og:url" content={data.metaURL} />
                                    <meta name="twitter:title" content={data} />
                                    <meta name="twitter:description" content={data.metaDescription} />
                                    <meta name="twitter:image" content={data} />
                                    <meta name="twitter:card" content={data} />
                                </Helmet>
                                <PortfoliosSec
                                    id={item.id}
                                    title={item.title}
                                    content={item.content}
                                    bgImg={item.bgImg}
                                    isReverse={item.isReverse}
                                    isHeadingIcon={item.icon}
                                />
                            </>
                        )
                    })
                }
            </PrimaryLayout>
        </div>
    )
}

export default FintechSolutions