import React, { useState } from 'react'
import InputGroup from '../../common/InputGroup/InputGroup'
import { toast } from 'react-toastify';
//import { handleContactUs } from '../../../Services/contactUsServices';
import emailjs from 'emailjs-com';

function ContactUs({ isContactUsAltBg }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isValidName, setIsValidName] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailSend = () => {
     //  e.preventDefault();

        // Validation checks
        // let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        // if (!name || name.length < 3) {
        //     toast.error("Enter a valid name with at least 3 characters");
        //     return;
        // }
        // if (!email || !emailReg.test(email)) {
        //     toast.error("Enter a valid email");
        //     return;
        // }

        // EmailJS integration
        const serviceID = 'service_t8gfhp1'; // Replace with your EmailJS Service ID
        const templateID = 'template_5divtne'; // Replace with your EmailJS Template ID
        const userID = '2j3pph6t1jLUQfZmx'; // Replace with your EmailJS User ID

       // console.log("response",name,email,message)

        emailjs.send(serviceID, templateID, {
            name: name,
            email: email,
            message: message,
        }, userID)
        .then((response) => {
            toast.success("Thanks for contacting us!");
            setName('');
            setEmail('');
            setMessage('');
        })
        .catch((err) => {
            toast.error('Failed to send message. Please try again.');
        });
    };

    return (
        <div id='contact-us' className={`contact-us py-[3.2rem] ${isContactUsAltBg ? "bg-contact-us-alt-bg contact-us-alt-bg bg-cover h-full w-full" : "bg-[#F3F5FB]"} relative`}>
            <div className='contact-us-container flex flex-col justify-center items-center container-lg px-[4%] sm:px-[10%]'>
                <h2 className={`section-heading text-center text-[4rem] xl:text-[5rem] ${isContactUsAltBg ? "text-[#FFFFFF]" : "text-[#5C33A5]"} font-[500] mb-[1rem]`}>
                    Get in Touch 
                </h2>
                <h2 className={`section-subheading text-center text-[2.4rem] md:text-[3.2rem] xl:text-[4.5rem] font-[300] ${isContactUsAltBg ? "text-[#FFFFFF]" : "text-[#000000]"} mb-[2.4rem]`}>
                    It will have a ‘Get in touch’ link to it, as shown in the screenshot below
                </h2>
                <div className={`flex flex-col justify-center items-center w-full px-[0] lg:px-[4rem] [@media(min-width:1319px)]:px-[10rem] ${isContactUsAltBg ? "text-[#FFFFFF]" : "text-[#000000]"}`}>
                    <div className='grow contact-us-form grid grid-cols-2 gap-y-6 gap-x-10 sm:gap-x-20 w-full'>
                        <InputGroup
                            isRequired={true}
                            label='Name'
                            value={name}
                            setValue={setName}
                            inputType="text"
                            inputPlaceholder={'Name'}
                            isContactUsAltBg={isContactUsAltBg}
                            // isFocused={true}
                        />
                        <InputGroup
                            isRequired={true}
                            label='Email'
                            value={email}
                            setValue={setEmail}
                            inputType="email"
                            inputPlaceholder={'Email Address'}
                            isContactUsAltBg={isContactUsAltBg}
                        />
                    </div>
                    <div className="grow contact-us-form grid grid-cols-1 mt-[4rem] gap-y-6 gap-x-20 w-full">
                        <textarea 
                            className={`w-full p-8 text-2xl placeholder-white bg-[#BECAF3]/[.21] rounded-[1rem] focus:outline-0 ${isContactUsAltBg ? "border-b-[#54BEFE] focus-within:border-b-[#130835]" : "border-b-[#54BEFE] border-b-[#54BEFE] focus-within:border-b-[#130835]"} border-b-[.3rem]`}
                            rows="3" cols="50"
                            type="text"
                            placeholder="Message"
                            value={message}
                            onChange={(e) => {
                                setMessage(e.target.value)
                            }}
                        />
                    </div>
                    <div className="grow contact-us-form grid grid-cols-1 gap-y-6 mt-[4rem] gap-x-20 w-full">
                        <button
                            className='w-full h-[5.9rem] bg-[#3EBAF6] rounded-[.8rem] mt-auto text-[#0C2E87] text-lg font-[700] hover:bg-[#130835]'
                            onClick={() => {
                                let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                                if (!name) {
                                    setIsValidName(false)
                                } else {
                                    setIsValidName(true)
                                }
                                if (!email) {
                                    setIsValidEmail(false)
                                } else {
                                    setIsValidEmail(true)
                                }
                                if (name.length < 3) {
                                    toast.error("Enter minimum 3 characters Name")
                                }
                                if (!name) {
                                    toast.error("Enter a valid name")
                                    return;
                                }
                                if (!email) {
                                    toast.error("Enter a valid email")
                                    return;
                                }
                                if (!emailReg.test(email)) {
                                    toast.error("Enter a valid email")
                                    return;
                                }
                               // handleContactUs({
                                handleEmailSend({
                                    "fullName": name,
                                    "email": email,
                                    "message": message
                                })
                                setName('')
                                setEmail('')
                                setMessage('')
                            }}
                        >
                            Send it Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
