import React, { useEffect, useState } from 'react'
import { InnovativeSecurityProductsComponentCardImgs, PortfolioImgs } from "../../Static/constants/imgConstants/imgConstants"
import PortfoliosSec from '../../Components/main/PortfoliosSec/PortfoliosSec';
import PrimaryLayout from '../../Components/common/layout/PrimaryLayout';
import HomeLandingSections from '../../Components/common/HomeLandingSections/HomeLandingSections';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Portfolios({ selectedPortfolio, setSelectedPortfolio, setSelectedCard, setSelectedProduct }) {

    const navigate = useNavigate();

    const [data] = useState([
        {
            id: "enterprise-automation",
            title: "Enterprise Automation",
            content: [
                "We provide comprehensive business process automation solution covering all business process management steps from analysis and modeling to automation and reporting. The automation process is geared toward designing and implementing software applications to automate routine tasks from initiation to execution and completion, while obtaining enterprise-wide business process efficiency.",
                "Our business process automation solution is based on the innovative technology and allows users to run and modify business processes with drag-and-drop simplicity and minimizes dependency on IT staff.Our BPA software empowers you to: Improve business operations performance,Enhance visibility into business processes,Reduce costs and risks of malfunctioning processes.",

            ],
            bgImg: PortfolioImgs.portfolioImg4,
            isReverse: false,
        },
        {
            id: "visitor-management",
            title: "Visitor Management Solutions",
            content: [
                "Visitor management refers to tracking the usage of a public building or site.By gathering increasing amounts of information, a Visitor Management System can record the usage of the facilities by specific visitors and provide documentation of visitor's whereabouts. For organizations that regularly host events and conferences on-site, the pre-booking feature allows visitors and guests to pre-register their attendance prior to arriving, effectively reducing their check-in time from a 1-2 minute process down to a couple of seconds. With some simple configuration, Visitor Management solutions can be designed to request unique reference numbers as the final stage of a check-in process, making the check-in process even easier.",
                "The final stage of a Visitor Management solutions check-in process is the badge printing. Regardless of whether a visitor pre-registers or completes the check-in on arrival, customised ID badges can be printed in seconds as part of a modern Visitor Management solution. In some cases where Access Control integrations have been made, ID badges can be scanned at control points to gain access to zones that have been configured to grant authorisation. For any future-proof solution on the market, it’s important to consider how well it integrates with other solutions.",

            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg10,
            isReverse: true,
        },
        {
            id: "time-attendance",
            title: "Time & Attendance Solutions",
            content: [
                "Secure World's time & attendance management solution simplifies employee timesheet monitoring, calculation of work hours and collection of time & attendance data. Delegated authentication is impossible because it is a biometrics-based time & attendance management system and you can set up various types of work schedule/work shift. It enables human resources management and time & attendance management in one system since you can easily connect with HR or ERP systems and easily see personalized time & attendance reports and calendar view at a glance.",
                "Easy connection with HR and ERP systems.You can connect the system to enable human resources management and time & attendance management in one system. Biometric-based accurate time & attendance management.Flexible work management. Easy work schedule management.Setting various work shifts Providing personalized time & attendance report and calendar view. Time Attendance Mobile enables you to freely control Time Attendance anywhere, anytime on your smart device.Developed based on Time Attendance's web APIs, Time Attendance Mobile provides services appropriate for the mobile era, including user information registration, BioMini-based fingerprint registration, door control, real-time door and zone status notification, and access control event monitoring.",

            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg9,
            isReverse: false,
        },
        {
            id: "queue-management",
            title: "Queue Management System",
            content: [
                "Are you facing problems like unorganized waiting areas, increased customer waiting time, increased employee idle time, frustrated customers due to unfair serving, unable to monitor the performance of a branch located in a distant location, in short, are you unable to create a unique customer experience which can be your competitive edge? Relax!!! We can solve your problem. Our Q management system in Dubai is designed not only to address all your problems but also help you create an environment where your customers can feel at home even while waiting.",
                "Our queue management system in Dubai can redefine customer experience and help you create a competitive edge.Our Q management system in Dubai is ideal for Banks, Telecom Service Centers, Government Institutions, Hospitals, Retail, Airlines, Embassies or any other organization which encounters heavy flow of customers. Moreover, our queue management system In Dubai is designed to help you create a service delivery environment where customers can enjoy a memorable experience resulting into the highest levels of customer satisfaction, consequently making them more loyal to your brand.",

            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg11,
            isReverse: true,
        },
        {
            id: "smartron",
            title: "Smart Meeting Solutions",
            content: [
                "We are counted amongst the leading meeting room planners in UAE. With our smart meeting room solutions, you can easily plan a business meeting as per your schedule and requirements. The meeting room booking solution that we offering to the clients prove out to be very beneficial as it provides access to various options as well. Simplify Conference Room Scheduling With Meeting Planner & Sync With Outlook. The SMART MEETING Room Solutions that we provide are widely known for their higher reliability. Reduce costs and simplify room scheduling with MeetingPlanner, the #1 Microsoft Outlook integrated, mobile device, digital signage and web-based room booking software available.",
                "MeetingPlanner is the only scheduling solution that provides options for Microsoft Outlook integration, web access, mobility options, digital signage integration and Microsoft Exchange Resource synchronization. Secure World has over 15 years of proven experience in Software Solution; we launched smart meeting room solutions for Microsoft Outlook and Exchange experience for scheduling conference rooms, office space, equipment, catering, video conference and other services with MeetingPlanner. MeetingPlanner is installed all over the world with organizations ranging from large global fortune 100 companies, hospitals, non-profits, government and educational institutions.",

            ],
            bgImg: InnovativeSecurityProductsComponentCardImgs.InnovativeSecurityProductsComponentCardImg12,
            isReverse: false,
        },
        {
            id: "assetron",
            title: "Asset Management Solution",
            content: [
                "Our good asset management solution will help you optimize performance vs. risk taken, let you create what-if-scenarios and stress tests, trade any kind of financial instrument, create flexible investment restrictions and easily monitor compliance online, compare your performance to a benchmark’s, and produce detailed analytics. And because reconciling your positions with your custodian may increase your back-office burden, such software should also provide seamless position keeping and automated reconciliation tools.",
                "Manage funds of all types, as well as corporate entities, private clients, pension funds and endowments. Allows you to create what-if-scenarios, chart portfolio optimization and visualize the impact of all types of exposure. Provides clients and financial advisors with Web access.Comes with industry-standard messaging interfaces that lets you stay connected to counterparties and providers 24/7 and reconcile your data with them. Allow to respond quickly and thoroughly in the event of an audit.",

            ],
            bgImg: PortfolioImgs.portfolioImg3,
            isReverse: true,
        },
        {
            id: "web-development",
            title: "Web/ Mobile App development",
            content: [
                "Transforming Operations: Our web application development services provide tailored digital solutions aligned with the latest technical and business standards. As custom web developers, we deeply analyse your business ecosystem, technology infrastructure, and innovation capacity to craft a digital strategy that evolves your business. Whether it's creating a professional website from scratch, redesigning, upgrading, or scaling existing web assets, our team ensures successful implementation, surpassing your expectations.",
                "Tech Expertise: With a rich tech stack and solid enterprise-level product development experience, we excel in high-load, scalable systems using MicroService architecture and various integrations. As your technology partner, we offer diverse skills and industrial expertise, catering to startups and large enterprises across healthcare, e-commerce, energy, real estate, photography, and banking domains.",
                "Mobile App Excellence: Our experienced mobile applications developers deliver predictably successful results using a proactive and agile approach. With a decade-long expertise spanning various industries, from retail to government, we optimise time and investment at every development step. Customised mobile app development is our forte, unlocking the full potential of mobile technology for your company. We tailor our services to your business goals, ensuring top-quality and smooth applications that evolve with technological advancements."

            ],
            bgImg: PortfolioImgs.portfolioImg1,
            isReverse: false,
        },
    ]);

    useEffect(() => {
        setTimeout(() => {
            if (selectedPortfolio) {
                window.location.href = "#" + selectedPortfolio
            }
        }, 1000);
    }, [selectedPortfolio])


    return (
        <>
            <Helmet>
                <title></title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="author" content="" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                <meta name="twitter:title" content="" />    
                <meta name="twitter:description" content="" />
                <meta name="twitter:image" content="" />
                <meta name="twitter:card" content="" />
            </Helmet>
            <div className='portfolios-sec'>
                <PrimaryLayout
                    showNavbar={true}
                    showContactUs={true}
                    showFooterMain={true}
                    showFooterMini={true}
                    isContactUsAltBg={true}
                    setSelectedPortfolio={setSelectedPortfolio}
                    setSelectedCard={setSelectedCard}
                    setSelectedProduct={setSelectedProduct}
                >
                    <HomeLandingSections
                        title1={`Forging Future`}
                        title2={`\nFrontiers`}
                        subtitle={`Pioneering Innovation\nin Robotic Process Automation (RPA) .`}
                        route={"portfolio"}
                    />
                    <div className="our-offerings-sec bg-[#F7F7FF] py-[2rem]">
                        <div className="container-lg flex flex-col justify-center items-start">
                            <h1 className="text-[4rem] lg:text-[5rem] font-[500] text-[#00E0FF]">
                                Our Portfolio
                            </h1>
                            <h2 className='text-[2rem] lg:text-[3rem] text-[#FFFFFF] font-[500] leading-[3rem] lg:leading-[4rem]'>
                                We are purely an IT projects company into Software & IT Automation with Latest Surveillance Securities.
                            </h2>
                            <p className="text-[#FFFFFF] text-[2.1rem]">
                                Revolutionizing Surveillance Security Through IT Automation.
                            </p>
                        </div>
                    </div>
                    {data.map((item) => {
                        return (
                            <PortfoliosSec
                                id={item.id}
                                title={item.title}
                                content={item.content}
                                bgImg={item.bgImg}
                                isReverse={item.isReverse}
                            />
                        )
                    })
                    }
                </PrimaryLayout>
            </div>
        </>
    )
}

export default Portfolios